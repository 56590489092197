import { Component, ErrorInfo, ReactNode } from 'react';
import { persistanceCacheKey } from 'recoil/persistanceCacheEffect';
import { truckMapConfig } from 'truckMapConfig';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error & { truckmapData?: object }, errorInfo: ErrorInfo) {
    error.truckmapData = {
      errorInfo,
      route: truckMapConfig.isClientSideRender ? window.location.href : undefined,
      recoilState: truckMapConfig.isClientSideRender
        ? window.localStorage.getItem(persistanceCacheKey) || {}
        : {}
    };

    console.error('Uncaught error:', error, error.truckmapData);
  }

  public render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
