import { Inter } from 'next/font/google';
import React from 'react';

type FontWrapperProps = ChildrenProp;

const inter = Inter({
  weight: 'variable',
  display: 'block',
  subsets: ['latin', 'latin-ext']
});

export const FontWrapper: React.FC<FontWrapperProps> = React.memo(({ children }) => {
  return <div className={inter.className}>{children}</div>;
});

FontWrapper.displayName = 'FontWrapper';
