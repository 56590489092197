const config = {
  name: 'TruckMap',
  price: '0',
  priceCurrency: 'USD',
  aggregateRating: { ratingValue: '4.7', ratingCount: '27400' },
  operatingSystem: 'iOS, ANDROID',
  applicationCategory: 'TravelApplication'
};

export default config;
