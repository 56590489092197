import { useRouter } from 'next/router';
import {
  DefaultSeo as NextDefaultSeo,
  LogoJsonLd,
  SocialProfileJsonLd,
  SoftwareAppJsonLd
} from 'next-seo';
import { truckMapConfig } from 'truckMapConfig';

import socialProfileConfig from './social.config';
import softwareAppConfig from './software.config';

export const DefaultSeo = () => {
  const { baseUrl } = truckMapConfig;
  const router = useRouter();
  const isPlaceRelatedPage = router.pathname.startsWith('/place');

  return (
    <>
      <NextDefaultSeo
        {...truckMapConfig.seo}
        openGraph={{
          site_name: truckMapConfig.siteName,
          locale: router?.locale ?? truckMapConfig.i18n.defaultLocale,
          type: 'website',
          images: [
            { url: baseUrl + '/images/social-share-banner.jpg', width: 1200, height: 630, alt: '' }
          ]
        }}
        twitter={{
          handle: '@truckmaphq',
          site: '@truckmaphq',
          cardType: 'summary_large_image'
        }}
      />
      <SocialProfileJsonLd {...socialProfileConfig} />
      {/* This should later be replaced by https://github.com/garmeeh/next-seo#corporate-contact */}
      <LogoJsonLd logo={`${baseUrl}/images/logo.svg`} url={baseUrl} />
      {/* These might be populatable via api later */}
      {!isPlaceRelatedPage && <SoftwareAppJsonLd {...softwareAppConfig} />}
    </>
  );
};
