import { recoilPersist } from 'recoil-persist';
import { truckMapConfig } from 'truckMapConfig';

export const persistanceCacheKey = `${truckMapConfig.persistanceCacheKey}${
  truckMapConfig.isStaging ? '-staging' : ''
}`;

export const persistanceCacheEffect = recoilPersist({
  key: persistanceCacheKey
});

export function getPersistanceCacheData() {
  const localStorageCacheData = localStorage.getItem(persistanceCacheKey);

  return localStorageCacheData ? JSON.parse(localStorageCacheData) : {};
}
