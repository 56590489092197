import { SocialProfileJsonLdProps } from 'next-seo/lib/jsonld/socialProfile';
import { truckMapConfig } from 'truckMapConfig';

const config: SocialProfileJsonLdProps = {
  type: 'Organization',
  name: truckMapConfig.siteName,
  url: truckMapConfig.baseUrl,
  sameAs: Object.values(truckMapConfig.social)
};

export default config;
